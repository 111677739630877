<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/media-library">Media Library</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ ($route.query.what == "add") ? "Add To" : "Remove From" }} Index</li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-center">
    <FlashMessage :group="flash_group" />
  </div>

  <div class="d-flex justify-content-left mt-3">
    <div class="col-12">
      <div class="row">
        <div class="col-3">
          <label for="" class="form-label">{{ ($route.query.what == "add") ? "Add" : "Remove" }} Media: </label>
        </div>
        <div class="col-9">
          <span v-for="(sha, i) in splitList" :key="i">
            {{ sha[0] }} ({{ sha[1] }})<br />
          </span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-3">
          <label for="" class="form-label">{{ ($route.query.what == "add") ? "To Index" : "From Index" }}</label>
        </div>
        <div class="col-9">
          <Multiselect v-model="selected_indexes" mode="tags" :searchable="true"
            :createTag="true" :options="indexes" />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col text-end">
          <button class="btn btn-primary" @click="processMedia($route.query.what)">
            Execute
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { ref, onMounted, inject } from 'vue';
  import axios from 'axios';
  import Multiselect from '@vueform/multiselect';
  import { flashMessage } from '@smartweb/vue-flash-message';
  import { useRoute } from 'vue-router';

  export default {
    components: {
      Multiselect,
    },

    props: {
    },

    setup() {
      const selected_indexes = ref([]);
      const indexes = ref([]);

      const now = new Date();
      const ctime = now.getTime();

      const flash_group = `media_index_upsert.${ctime}`;
      const route = useRoute();

      onMounted(async() => {
        const token = await inject('authToken');

        // Propagate indexes.
        axios.get("/media-1.0/indexes?assetType=" + route.query.chosen_media, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          let ret_array = [];
          response.data.forEach((name) => {
            ret_array.push({"value": name.index_name, "label": name.index_name});
          });

          indexes.value = ret_array;
        });  
      });

      return {
        selected_indexes,
        indexes,
        flash_group,
      }
    },

    methods: {
      async processMedia(what) {
        const token = await this.$auth.getTokenSilently();

        this.selected_indexes.forEach((index) => {
          this.$route.query.shas.forEach((sha) => {
            if (what == "add") {
              axios.post('/media-1.0/indexes/' + index + '/asset/' + sha, {}, {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              }).then(() => {
                flashMessage.show({
                  type: "success",
                  title: "Updated Index " + index,
                  text: sha + " added to index " + index,
                  group: this.flash_group
                });
              });
            }
            else {
              axios.delete('/media-1.0/indexes/' + index + '/asset/' + sha, {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              }).then(() => {
                flashMessage.show({
                  type: "success",
                  title: "Updated Index " + index,
                  text: sha + " removed from index " + index,
                  group: this.flash_group
                });
              });
            }
          });
        });
      },
    },

    computed: {
      splitList(){
        let newArr = [...this.$route.query.shas];
        let new_arr = [];

        newArr.map(el => {
          let new_chunk = el.split(':');
          new_arr.push([new_chunk[0], new_chunk[1]]);
        });

        return new_arr;
      }
    },
  }
</script>

<style scoped>

</style>