<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="/campaigns">Campaigns</a></li>
        <li v-if="campaign_id === ''" class="breadcrumb-item active" 
          aria-current="page">Create Campaign</li>
        <li v-else class="breadcrumb-item active" aria-current="page">Edit Campaign</li>
      </ol>
    </nav>
  </div>

  <div>
    <div class="d-flex justify-content-center">
      <h1>Campaign {{ (campaign_id == '') ? "Creation" : "Edit" }}</h1>
    </div>

    <div class="d-flex justify-content-center">
      <FlashMessage :group="flash_group" />
    </div>

    <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="campaign_id !== ''">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="campaign-tab" data-bs-toggle="tab" data-bs-target="#campaign" 
          type="button" role="tab" aria-controls="campaign" aria-selected="true">
          Campaign
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="rules-tab" data-bs-toggle="tab" data-bs-target="#rules"
          type="button" role="tab" aria-controls="rules" aria-selected="false">
          Rules
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <div id="campaign" class="tab-pane active" role="tabpanel" aria-labelledby="campaign-tab">
        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3" :class="{'alert-danger': v$.name.$errors.length}">
              <div class="col-3">
                <label for="name" class="form-label">Campaign Name: </label>
              </div>
              <div class="col-9">
                <input v-model="form.name" type="text" class="form-control" id="name" />
                <span v-if="v$.name.$errors.length">Campaign Name is required.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3" :class="{'alert-danger': v$.desc.$errors.length}">
              <div class="col-3">
                <label for="desc" class="form-label">Description: </label>
              </div>
              <div class="col-9">
                <input v-model="form.desc" type="text" class="form-control" id="desc" />
                <span v-if="v$.desc.$errors.length">Campaign Index is required.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3">
              <div class="col-3">
                <label for="notes" class="form-label">Notes: </label>
              </div>
              <div class="col-9">
                <textarea v-model="form.notes" id="notes" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3">
              <div class="col-3">
                <label for="start_time" class="form-label">Start Time: </label>
              </div>
              <div class="col-9">
                <input v-model="form.start_time" type="text" class="form-control" 
                  id="start_time" placeholder="mm/dd/yyyy hh:ss" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3">
              <div class="col-3">
                <label for="desc" class="form-label">End Time: </label>
              </div>
              <div class="col-9">
                <input v-model="form.end_time" type="text" class="form-control" 
                  id="end_time" placeholder="mm/dd/yyyy hh:ss" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-10">
            <div class="row pt-3 mb-3" :class="{'alert-danger': v$.index.$errors.length}">
              <div class="col-3">
                <label for="index" class="form-label">Campaign Index: </label>
              </div>
              <div class="col-9">
                <Multiselect
                  v-model="form.index"
                  mode="single"
                  :searchable="true"
                  :options="campaign_indexes" 
                  @change="listMedia($event)" />
                <span v-if="v$.index.$errors.length">Campaign Index is required.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-3 mb-3 align-items-end">
          <div class="col-11">
          </div>
          <div class="col-1">
            <a href="#" class="btn btn-primary" @click="saveCampaign()" :disable="process_campaign">
              <fa :icon="['fas', 'save']" /> Save Campaign
            </a>
          </div>
        </div>
      </div>

      <div id="rules" v-if="campaign_id !== ''" class="tab-pane" role="tabpanel" aria-labelledby="rules-tab">
        <div class="row" v-for="(spot, i) in spots" :key="i">
          <div class="col-2">
          </div>
          <div class="col-9">
            <div class="row pt-3 mb-3">
              <div class="col-8">{{ spot.original_filename }}</div>
              <div class="col-4 text-end">
                <button type="button" class="btn btn-primary" @click="openModal(spot)"><fa :icon="['fas', 'plus']" /> Add Rule</button>
              </div>
            </div>
            <div v-for="(rule, i) in form.rules[spot.sha256sum]" :key="i" class="row pt-3 mb-3">
              <div class="col-8">
                <div class="row">
                  <div class="col-1">
                  </div>
                  <div class="col">
                    {{ rule.short_description }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-1">
                  </div>
                  <div class="col">
                    {{ rule.notes }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-1">
                  </div>
                  <div class="col">
                    {{ rule.predicates }}
                  </div>
                </div>
              </div>
              <div class="col-4 text-end">
                <button type="button" class="btn btn-primary" @click="editRule(spot.original_filename, campaign_id, rule.uuid)"><fa :icon="['fas', 'edit']" /> Edit Rule</button>
                &nbsp;
                <button type="button" class="btn btn-primary" @click="removeRule(spot.sha256sum, i)"><fa :icon="['fas', 'times-circle']" /> Delete Rule</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="rulesModal" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ (modal.edit) ? "Edit" : "Create" }} Rule for {{ modal.filename }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-2">
            <div class="col-2">
              Short Description:
            </div>
            <div class="col-10">
              <input v-model="modal.short_desc" type="text" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="row mb-2">
              <div class="col-2">
                Notes:
              </div>
              <div class="col-10">
                <textarea v-model="modal.notes" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6 text-center">
              Selected Rule(s)
            </div>
            <div class="col-6 text-center">
              Rules Bank
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <draggable
                class="dragArea list-group"
                :list="modal_rules"
                group="rules"
                itemKey="id"
              >
                <template #item="{ element }">
                  <div>
                    <div v-if="element.what === 'DateRange'" class="list-group-item">
                      <div class="row pb-1" 
                        :class="{'alert-danger': v1$.dateStart.$errors.length || v1$.dateEnd.$errors.length || dateEndError !== ''}">
                        <div class="col">
                          Date Range: 
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Only run within the specified date range. Does not introduce spacing." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.dateStart.$errors.length || v1$.dateEnd.$errors.length || dateEndError !== ''}">
                        <div class="col">
                          From <input v-model="modal.dateStart" class="form-control" type="text" placeholder="yyyy-mm-dd" /> 
                          To <span class="small">(Optional)</span><input v-model="modal.dateEnd" class="form-control" type="text" placeholder="yyyy-mm-dd" />
                          <span class="" v-if="v1$.dateStart.$errors.length">Starting date must be in the format of yyyy-mm-dd<br /></span>
                          <span v-if="v1$.dateEnd.$errors.length">Ending date must be in the format of yyyy-mm-dd</span>
                          <span v-if="dateEndError !== ''">{{ dateEndError }}</span>
                        </div>
                      </div>
                    </div>
                    
                    <div v-if="element.what === 'EveryNHours'" class="list-group-item">
                      <div class="row pb-1" 
                        :class="{'alert-danger': v1$.everyNHours.$errors.length}">
                        <div class="col">
                          Every N Hours:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Play no more often than the given number of hours." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.everyNHours.$errors.length}">
                        <div class="col">
                          <input v-model="modal.everyNHours" type="number" class="form-control" min="0" max="23" />
                          <span v-if="v1$.everyNHours.$errors.length">Hours must be greater than 0.</span>
                        </div>
                      </div>
                    </div>
                    
                    <div v-if="element.what === 'EveryNMinutes'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.everyNMinutes.$errors.length}">
                        <div class="col">
                          Every N Minutes:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Play no more often than the given number of minutes." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.everyNMinutes.$errors.length}">
                        <div class="col">
                          <input v-model="modal.everyNMinutes" type="number" class="form-control" min="0" max="59" />
                          <span v-if="v1$.everyNMinutes.$errors.length">Minutes must be greater than 0.</span>
                        </div>
                      </div>
                    </div>
                    
                    <div v-if="element.what === 'TimeRange'" class="list-group-item">
                      <div class="row pb-1" 
                        :class="{'alert-danger': v1$.timeStart.$errors.length || v1$.timeEnd.$errors.length || timeEndError !== ''}">
                        <div class="col">
                          Time Range:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Only run within the specified time range. Does not introduce spacing." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.timeStart.$errors.length || v1$.timeEnd.$errors.length || timeEndError !== ''}">
                        <div class="col">
                          From <input v-model="modal.timeStart" class="form-control" type="text" placeholder="hh:mm" /> 
                          To <span class="small">(Optional)</span><input v-model="modal.timeEnd" class="form-control" type="text" placeholder="hh:mm" />
                          <span v-if="v1$.timeStart.$errors.length">Starting time must be in the format of hh:ss</span>
                          <span v-if="v1$.timeEnd.$errors.length">Ending time must be in the format of hh:ss</span>
                          <span v-if="timeEndError !== ''">{{ timeEndError }}</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'RepetitionsPerHour'" class="list-group-item">
                      <div class="row pb-1">
                        <div class="col">
                          Repetitions per Hour:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Limit to no more than the given number of plays per hour. Does NOT create spacing." />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input v-model="modal.repetitionsPerHour" type="number" class="form-control" />
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'OnMinutes'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.onMinutes.$errors.length}">
                        <div class="col">
                          On Minutes:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Play only on the given minutes of each hour. This can be used as an analog to the old 'exact block' setting." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.onMinutes.$errors.length}">
                        <div class="col">
                          <input v-model="modal.onMinutes" type="text" class="form-control" placeholder="min, min, min" />
                          <span v-if="v1$.onMinutes.$errors.length">On Minutes must be a list of minutes separated by a comma</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'OnHours'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.onHours.$errors.length}">
                        <div class="col">
                          On Hours:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Play only on the given hour. This can be used as an analog to the old 'exact block' setting." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.onHours.$errors.length}">
                        <div class="col">
                          <input v-model="modal.onHours" type="text" class="form-control" placeholder="hour, hour, hour" />
                          <span v-if="v1$.onHours.$errors.length">On Hours must be a list of hours separated by a comma</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'Probability'" class="list-group-item">
                      <div class="row pb-1">
                        <div class="col">
                          Probabaility:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Introduce randomness by playing with a certain probability. Each evaluation is independent of previous evaluations, so a '20% probability' is, on average, 1 in 5 opportunities, but actual behavior is entirely random. It is not guaranteed to produce exactly this play percentage." />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input v-model="modal.probability" type="number" class="form-control" min="0" max="100" />
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'ExactTime'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.exactTime.$errors.length}">
                        <div class="col">
                          Exact Time:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Schedule at a specific time. Actual play time is best effort, and would follow whatever media is currently playing and already-enqueued spots." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.exactTime.$errors.length}">
                        <div class="col">
                          <input v-model="modal.exactTime" type="text" class="form-control" placeholder="hh:mm" />
                          <span v-if="v1$.exactTime.$errors.length">Exact Time needs to be in the format hh:mm</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'DaysOfWeek'" class="list-group-item">
                      <div class="row mb-1">
                        <div class="col">
                          Days of the Week:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Only run on the specified day(s)." />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input v-model="modal.sun" type="checkbox" class="btn-check" id="btnCheckSun" autocomplete="off" value="0">
                          <label class="btn btn-outline-primary" for="btnCheckSun">Sun</label>&nbsp;

                          <input v-model="modal.mon" type="checkbox" class="btn-check" id="btnCheckMon" autocomplete="off" value="1">
                          <label class="btn btn-outline-primary" for="btnCheckMon">Mon</label>&nbsp;

                          <input v-model="modal.tue" type="checkbox" class="btn-check" id="btnCheckTue" autocomplete="off" value="2">
                          <label class="btn btn-outline-primary" for="btnCheckTue">Tue</label>&nbsp;

                          <input v-model="modal.wed" type="checkbox" class="btn-check" id="btnCheckWed" autocomplete="off" value="3">
                          <label class="btn btn-outline-primary" for="btnCheckWed">Wed</label>&nbsp;

                          <input v-model="modal.thu" type="checkbox" class="btn-check" id="btnCheckThu" autocomplete="off" value="4">
                          <label class="btn btn-outline-primary" for="btnCheckThu">Thu</label>&nbsp;

                          <input v-model="modal.fri" type="checkbox" class="btn-check" id="btnCheckFri" autocomplete="off" value="5">
                          <label class="btn btn-outline-primary" for="btnCheckFri">Fri</label>&nbsp;

                          <input v-model="modal.sat" type="checkbox" class="btn-check" id="btnCheckSat" autocomplete="off" value="6">
                          <label class="btn btn-outline-primary" for="btnCheckSat">Sat</label>
                        </div>
                      </div>
                    </div>

                    <div v-if="element.what === 'RepetitionsPerTimeRange'" class="list-group-item">
                      <div class="row pb-1" :class="{'alert-danger': v1$.reps.$errors.length || v1$.repsTimeStart.$errors.length || v1$.repsTimeEnd.$errors.length || repsTimeEndError !== ''}">
                        <div class="col">
                          Repetitions Per Time Range:
                        </div>
                        <div class="col text-end">
                          <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Limit to no more than the given number of plays within this time range. If the current time is outside of the given time range, this predicate will prevent the spot from playing. This does NOT introduce spacing." />
                        </div>
                      </div>
                      <div class="row" :class="{'alert-danger': v1$.reps.$errors.length || v1$.repsTimeStart.$errors.length || v1$.repsTimeEnd.$errors.length || repsTimeEndError !== ''}">
                        <div class="col">
                          <input v-model="modal.reps" type="number" class="form-control" placeholder="Repetitions" />
                          From <input v-model="modal.repsTimeStart" type='text' class="form-control" placeholder="hh:mm" />
                          To <input v-model="modal.repsTimeEnd" type='text' class="form-control" placeholder="hh:mm" />
                          <span v-if="v1$.reps.$errors.length">Repetitions must be greater than 0.<br /></span>
                          <span v-if="v1$.repsTimeStart.$errors.length">Starting time must be in the format hh:mm <br /></span>
                          <span v-if="v1$.repsTimeEnd.$errors.length">Ending time must be in the format hh:mm <br /></span>
                          <span v-if="repsTimeEndError !== ''">{{ repsTimeEndError }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <div class="col-6">
              <draggable
                class="dragArea list-group"
                :list="draggable_rules"
                group="rules"
                itemKey="id"
              >
                <template #item="{ element }">
                  <div>
                    <div class="list-group-item d-flex justify-content-between">
                      {{ element.what }}
                      <span>
                        <fa :icon="['fas', 'question']" data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="element.mouseover" />
                      </span>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col text-end">
              <button type="button" class="btn btn-primary" aria-label="Save Rule(s)" @click="processRules" :disabled="modal_rules.length === 0">
                <fa :icon="['fas', 'save']" /> Save Rule(s)
              </button>
              &nbsp;
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
                <fa :icon="['fas', 'times-circle']" /> Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, reactive, onMounted, inject } from 'vue';
  import axios from 'axios';
  import Multiselect from '@vueform/multiselect';
  import { flashMessage } from '@smartweb/vue-flash-message';
  import useVuelidate from '@vuelidate/core';
  import { required, between, minValue, helpers } from '@vuelidate/validators';
  import { Modal } from 'bootstrap';
  import draggable from "vuedraggable";

  export default {
    components: {
      Multiselect,
      draggable,
    },

    props: {
      campaign_id: {
        type: String,
        required: false,
        default: '',
      }
    },

    setup(props) {
      const modal_rules = ref([]);
      const draggable_rules = ref([
        {
          id: 0,
          what: 'DateRange',
          mouseover: 'Only run within the specified date range. Does not introduce spacing.',
        },
        {
          id: 1,
          what: 'EveryNHours',
          mouseover: 'Play no more often than the given number of hours.',
        },
        {
          id: 2,
          what: 'EveryNMinutes',
          mouseover: 'Play no more often than the given number of minutes.',
        },
        {
          id: 3,
          what: 'Probability',
          mouseover: 'Introduce randomness by playing with a certain probability. Each evaluation is independent of previous evaluations, so a "20% probability" is, on average, 1 in 5 opportunities, but actual behavior is entirely random. It is not guaranteed to produce exactly this play percentage.',
        },
        {
          id: 4,
          what: 'ExactTime',
          mouseover: 'Schedule at a specific time. Actual play time is best effort, and would follow whatever media is currently playing and already-enqueued spots.',
        },
        {
          id: 5,
          what: 'OnMinutes',
          mouseover: 'Play only on the given minutes of each hour. This can be used as an analog to the old "exact block" setting.',
        },
        { 
          id: 6,
          what: 'RepetitionsPerHour',
          mouseover: 'Limit to no more than the given number of plays per hour. Does NOT create spacing.',
        },
        {
          id: 7,
          what: 'TimeRange',
          mouseover: 'Only run within the specified time range. Does not introduce spacing.',
        },
        { 
          id: 8,
          what: 'DaysOfWeek',
          mouseover: 'Only run on the specified day(s).',
        },
        {
          id: 9,
          what: 'RepetitionsPerTimeRange',
          mouseover: 'Limit to no more than the given number of plays within this time range. If the current time is outside of the given time range, this predicate will prevent the spot from playing. This does NOT introduce spacing.',
        },
        {
          id: 10,
          what: 'OnHours',
          mouseover: 'Play only on the given hour. This can be used as an analog to the old "exact block" setting.',
        }
      ]);

      const form = reactive({
        campaign_id: '',
        rules: [],
        name: '',
        desc: '',
        notes: '',
        start_time: '',
        end_time: '',
        index: '',
      });

      const modal = reactive({
        edit: false,
        filename: '',
        sha: '',
        short_desc: '',
        notes: '',
        uuid: '',
        dateStart: '',
        dateEnd: '',
        timeStart: '',
        timeEnd: '',
        everyNHours: '',
        everyNMinutes: '',
        probability: '',
        exactTime: '',
        onMinutes: '',
        onHours: '',
        repetitionsPerHour: '',
        sun: '',
        mon: '',
        tue: '',
        wed: '',
        thu: '',
        fri: '',
        sat: '',
        reps: '',
        repsTimeStart: '',
        repsTimeEnd: '',
      });

      const campaign_indexes = ref([]);
      const spots = ref([]);
      const show_rules = reactive(new Map());
      const process_campaign = ref(false);

      const TimeMatch = helpers.regex(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/);
      const onMinutesMatch = helpers.regex(/^[0-5]*[0-9](,\s*[1-5]*[0-9])*$/);
      const onHoursMatch = helpers.regex(/^(0?[1-9]|1[0-9]|2[0-4])(,\s*(0?[1-9]|1[0-9]|2[0-4]))*$/);
      const dateRangeMatch = helpers.regex(/^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/);

      const rules = {
        name: { 
          required
        },

        desc: { 
          required
        },

        index: { 
          required
        },
      }

      const rules1 = {
        everyNHours: {
          minValue: minValue(1)
        },

        everyNMinutes: {
          minValue: minValue(1)
        },

        probability: {
          between: between(0,100)
        },

        exactTime: {
          TimeMatch
        },

        onMinutes: {
          onMinutesMatch
        },

        onHours: {
          onHoursMatch
        },

        dateStart: {
          dateRangeMatch
        },

        dateEnd: {
          dateRangeMatch,
        },

        timeStart: {
          TimeMatch
        },

        timeEnd: {
          TimeMatch,
        },

        reps: {
          minValue: minValue(1)
        },

        repsTimeStart: {
          TimeMatch
        },

        repsTimeEnd: {
          TimeMatch
        }
      }

      const dateEndError = ref('');
      const timeEndError = ref('');
      const repsTimeEndError = ref('');

      const v$ = useVuelidate(rules, form);
      const v1$ = useVuelidate(rules1, modal);

      const now = new Date();
      const ctime = now.getTime();
      const flash_group = `config.${ctime}`;

      // Take ctime and return mm/dd/yyyy hh:mm:ss format.
      const convertCTime = ((time) => {
        const dt = new Date(time * 1000);

        return `${
          (dt.getMonth()+1).toString().padStart(2, '0')}/${
          dt.getDate().toString().padStart(2, '0')}/${
          dt.getFullYear().toString().padStart(4, '0')} ${
          dt.getHours().toString().padStart(2, '0')}:${
          dt.getMinutes().toString().padStart(2, '0')}:${
          dt.getSeconds().toString().padStart(2, '0')}`
      });

      const myModal = '';

      onMounted(async() => {
        const token = await inject('authToken');

        await axios.get("/media-1.0/indexes?assetType=SPOT", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          let ret_array = [];
          response.data.forEach((name) => {
            ret_array.push({"value": name.index_name, "label": name.index_name});
          });
          campaign_indexes.value = ret_array;
        });
    
        if (props.campaign_id !== '') {
          axios.get("/config-1.0/campaigns/" + props.campaign_id, {
              'headers': {
              'Authorization': `Bearer ${token}`
            }
          }).then(response => {
            form.campaign_id = props.campaign_id;
            form.name = response.data.name;
            form.desc = response.data.short_description;
            form.notes = response.data.notes;
            form.start_time = (response.data.start_time !== null) ? convertCTime(response.data.start_time) : "";
            form.end_time = (response.data.end_time !== null) ? convertCTime(response.data.end_time) : "";
            form.index = response.data.index;

            axios.get("/media-1.0/indexes/" + form.index, {
              'headers': {
                'Authorization': `Bearer ${token}`
              }
            }).then(response => {
              spots.value = response.data;

              axios.get("/config-1.0/campaigns/" + props.campaign_id + "/rules", {
                'headers': {
                  'Authorization': `Bearer ${token}`
                }
              }).then(response => {
                response.data.forEach((rules_obj) => {
                  axios.get("/config-1.0/campaigns/" + props.campaign_id +"/rules/" + rules_obj.rule_id, {
                    'headers': {
                      'Authorization': `Bearer ${token}`
                    }
                  }).then(response => {
                    const tmp_obj = new Object({
                      target_asset: rules_obj.target_asset,
                      short_description: response.data.short_description,
                      notes: response.data.notes,
                      predicates: response.data.predicates,
                      uuid: response.data.rule_id
                    });

                    let swp_arr = form.rules[rules_obj.target_asset] || [];
                    swp_arr.push(tmp_obj);
                    form.rules[rules_obj.target_asset] = swp_arr;
                  });
                });
              });
            });
          });
        } 
      });

      return {
        modal_rules,
        draggable_rules,
        form,
        modal,
        campaign_indexes,
        spots,
        show_rules,
        process_campaign,
        flash_group,
        v$,
        v1$,
        dateEndError,
        timeEndError,
        repsTimeEndError,
        convertCTime,
        myModal,
      }
    },

    methods: {
      async saveCampaign() {
        this.v$.$touch();

        if (this.v$.$error) {
          return;
        }

        this.process_campaign = true;

        const token = await this.$auth.getTokenSilently();

        if (this.form.campaign_id === '') {
          this.form.campaign_id = this.genUuid();
        }

        const start_time = (this.form.start_time !== '') ? this.getCTime(this.form.start_time): null;
        const end_time = (this.form.end_time !== '') ? this.getCTime(this.form.end_time) : null;

        axios.post("/config-1.0/campaigns/" + this.form.campaign_id, {
          name: this.form.name,
          index: this.form.index,
          short_description: this.form.desc,
          notes: this.form.notes,
          start_time: start_time,
          end_time: end_time,
        },
        {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(() => {
          this.process_campaign = false;
          flashMessage.show({
            type: "success",
            title: "Save Campaign",
            text: "Campaign saved.",
            group: this.flash_group
          });
        }).catch(() => {
          flashMessage.show({
            type: "error",
            title: "Save Campaign",
            text: "There was an error saving the Campaign.",
            group: this.flash_group
          });
        });
      },

      genUuid() {  
        var uuidValue = "", k, randomValue;  
        for (k = 0; k < 32;k++) {  
          randomValue = Math.random() * 16 | 0;  
        
          if (k == 8 || k == 12 || k == 16 || k == 20) {  
            uuidValue += "-"  
          }  
          uuidValue += (k == 12 ? 4 : (k == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);  
        }  
        return uuidValue;  
      },

      async listMedia(e) {
        const token = await this.$auth.getTokenSilently();
        
        axios.get("/media-1.0/indexes/" + e, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.spots = response.data;
        });
      },

      getCTime(datetime) {
        const [date, time] = datetime.split(" ");
        const [month, day, year] = date.split("/");
        const [hours, secs] = time.split(":");

        return new Date(year, month-1, day, hours, secs).getTime() / 1000;
      },

      openModal(spot) {
        this.modal.filename = spot.original_filename;
        this.modal.sha = spot.sha256sum;

        this.myModal = new Modal(document.getElementById('rulesModal'), {
          keyboard: false,
          backdrop: 'static',
        });

        this.myModal.toggle();
      },

      closeModal() {
        this.resetModal();

        this.myModal.toggle();
      },

      resetModal() {
        this.draggable_rules = [
          {
            id: 0,
            what: 'DateRange',
            mouseover: 'Only run within the specified date range. Does not introduce spacing.',
          },
          {
            id: 1,
            what: 'EveryNHours',
            mouseover: 'Play no more often than the given number of hours.',
          },
          {
            id: 2,
            what: 'EveryNMinutes',
            mouseover: 'Play no more often than the given number of minutes.',
          },
          {
            id: 3,
            what: 'Probability',
            mouseover: 'Introduce randomness by playing with a certain probability. Each evaluation is independent of previous evaluations, so a "20% probability" is, on average, 1 in 5 opportunities, but actual behavior is entirely random. It is not guaranteed to produce exactly this play percentage.',
          },
          {
            id: 4,
            what: 'ExactTime',
            mouseover: 'Schedule at a specific time. Actual play time is best effort, and would follow whatever media is currently playing and already-enqueued spots.',
          },
          {
            id: 5,
            what: 'OnMinutes',
            mouseover: 'Play only on the given minutes of each hour. This can be used as an analog to the old "exact block" setting.',
          },
          { 
            id: 6,
            what: 'RepetitionsPerHour',
            mouseover: 'Limit to no more than the given number of plays per hour. Does NOT create spacing.',
          },
          {
            id: 7,
            what: 'TimeRange',
            mouseover: 'Only run within the specified time range. Does not introduce spacing.',
          },
          { 
            id: 8,
            what: 'DaysOfWeek',
            mouseover: 'Only run on the specified day(s).',
          },
          {
            id: 9,
            what: 'RepetitionsPerTimeRange',
            mouseover: 'Limit to no more than the given number of plays within this time range. If the current time is outside of the given time range, this predicate will prevent the spot from playing. This does NOT introduce spacing.',
          },
          {
            id: 10,
            what: 'OnHours',
            mouseover: 'Play only on the given hour. This can be used as an analog to the old "exact block" setting.',
          }
        ];

        this.modal_rules = [];
        this.modal.edit = false;
        this.modal.filename = '';
        this.modal.sha = '';
        this.modal.short_desc = '';
        this.modal.notes = '';
        this.modal.uuid = '';
        this.modal.dateStart = '';
        this.modal.dateEnd = '';
        this.modal.timeStart = '';
        this.modal.timeEnd = '';
        this.modal.everyNHours = '';
        this.modal.everyNMinutes = '';
        this.modal.probability = '';
        this.modal.exactTime = '';
        this.modal.onMinutes = '';
        this.modal.onHours = '';
        this.modal.repetitionsPerHour = '';
        this.modal.sun = '';
        this.modal.mon = '';
        this.modal.tue = '';
        this.modal.wed = '';
        this.modal.thu = '';
        this.modal.fri = '';
        this.modal.sat = '';
        this.modal.reps = '';
        this.modal.repsTimeStart = '';
        this.modal.repsTimeEnd = '';
      },

      async processRules() {
        this.v1$.$touch();

        if (this.v1$.$error) {
          return;
        }

        // Extra validation because I can't get vuelidate to work with this.
        if (this.modal.dateEnd !== '') {
          if (new Date(this.modal.dateEnd).getTime() < new Date(this.modal.dateStart).getTime()) {
            this.dateEndError = 'Ending Date must be greater than or equal to the Starting Date';
            return;
          }
        }

        if (this.modal.timeEnd !== '') {
          if (new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.timeEnd).getTime() < new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.timeStart).getTime()) {
            this.timeEndError = 'Ending Time must be greater than or equal to the Starting Time';
            return;
          }
        }

        if (this.modal.repsTimeEnd !== '') {
          if (new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.repsTimeEnd).getTime() < new Date(new Date().getMonth()+1 + "/" + new Date().getDate() + "/" + new Date().getFullYear() + " " + this.modal.repsTimeStart).getTime()) {
            this.repsTimeEndError = "Ending Time must be greater than or equal to the Starting Time";
            return;
          }
        }

        this.dateEndError = '';
        this.timeEndError = '';
        this.repsTimeEndError = '';
        let rules = [];

        this.modal_rules.forEach((rule) => {
          switch(rule.what) {
            case 'DateRange': {
              let tmp_obj = { start_date: this.modal.dateStart };

              if (this.modal.dateEnd !== "") {
                tmp_obj['end_date'] = this.modal.dateEnd;
              }

              rules.push({DateRange: tmp_obj});
              break;
            }
            case 'TimeRange': {
              let tmp_obj = { start_time: this.modal.timeStart + ":00" };

              if (this.modal.timeEnd !== "") {
                tmp_obj['end_time'] = this.modal.timeEnd + ":00";
              }

              rules.push({TimeRange: tmp_obj});
              break;
            }
            case 'DaysOfWeek':
              rules.push({DaysOfWeek: { days: this.setDays([this.modal.sun, this.modal.mon, this.modal.tue, this.modal.wed, this.modal.thu, this.modal.fri, this.modal.sat])}});
              break;
            case 'OnMinutes':
              rules.push({OnMinutes: { minutes: this.setTimeToNumbers(this.modal.onMinutes) }});
              break;
            case 'OnHours':
              rules.push({OnHours: { hours: this.setTimeToNumbers(this.modal.onHours) }});
              break;
            case 'ExactTime':
              rules.push({ExactTime: { fire_time: this.modal.exactTime + ":00" }});
              break;
            case 'EveryNMinutes':
              rules.push({EveryNMinutes: { n: parseInt(this.modal.everyNMinutes, 10) }});
              break;
            case 'EveryNHours':
              rules.push({EveryNHours: { n: parseInt(this.modal.everyNHours, 10) }});
              break;
            case 'Probability':
              rules.push({Probability: { pct_probability: parseInt(this.modal.probability, 10) }});
              break;
            case 'RepetitionsPerHour':
              rules.push({RepetitionsPerHour: { repetitions: parseInt(this.modal.repetitionsPerHour, 10) }});
              break;
            case 'RepetitionsPerTimeRange':
              rules.push({RepetitionsPerTimeRange: { n: parseInt(this.modal.reps, 10), start_time: this.modal.repsTimeStart + ":00", end_time: this.modal.repsTimeEnd + ":00" }});
              break;
          }
        });

        let rules_swp = this.form.rules[this.modal.sha];

        if (rules_swp === undefined) {
          rules_swp = [];
        }

        const uuid = (this.modal.uuid === "") ? this.genUuid() : this.modal.uuid;

        if (this.form.rules[this.modal.sha] && this.form.rules[this.modal.sha].length > 0) {
          const index = this.form.rules[this.modal.sha].findIndex(p => p.uuid == this.modal.uuid);

          if (index !== -1) {
            this.form.rules[this.modal.sha].splice(index, 1);
          }
        }

        rules_swp.push(new Object({
            'target_asset': this.modal.sha,
            'short_description': this.modal.short_desc,
            'notes': this.modal.notes,
            'uuid': uuid,
            'predicates': rules,
        }));

        this.form.rules[this.modal.sha] = rules_swp;
        this.$forceUpdate();

        const token = await this.$auth.getTokenSilently();

        axios.post("/config-1.0/campaigns/" + this.form.campaign_id + "/rules/" + uuid, {
          target_asset: this.modal.sha,
          short_description: this.modal.short_desc,
          notes: this.modal.notes,
          predicates: rules
        },
        {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        });

        this.closeModal();
      },

      lowerCaseFirstLetter(string) {
        return string[0].toLowerCase() + string.slice(1);
      },

      clearValue(what) {
        if (what == "DateRange") {
          this.modal.dateStart = '';
          this.modal.dateEnd = '';
        }
        else if (what == "TimeRange") {
          this.modal.timeStart = '';
          this.modal.timeEnd = '';
        }
        else if (what == "DaysOfTheWeek"){
          this.modal.sun = '';
          this.modal.mon = '';
          this.modal.tue = '';
          this.modal.wed = '';
          this.modal.thu = '';
          this.modal.fri = '';
          this.modal.sat = '';
        }
        else {
          this.modal[this.lowerCaseFirstLetter(what)] = '';
        }
      },

      setDays(week) {
        let return_arr = [];
        let day_value = 0;

        week.forEach(day => {
          if (day) {
            return_arr.push(day_value);
          }
          day_value++;
        });

        return return_arr;
      },

      setTimeToNumbers(arr_str) {
        let arr = arr_str.split(",");

        for (let i = 0; i < arr.length; i++) {
          arr[i] = parseInt(arr[i], 10);
        }

        return arr;
      },

      async removeRule(sha, pos) {
        let arr = this.form.rules[sha];
        let rule = arr.splice(pos, 1);
        this.form.rules[sha] = arr;

        if (rule[0].uuid) {
          const token = await this.$auth.getTokenSilently();
          axios.delete("/config-1.0/campaigns/" + this.form.campaign_id + "/rules/" + rule[0].uuid, {
            'headers': {
              'Authorization': `Bearer ${token}`
            }
          });
        }
      },

      convertDaysOfWeek(week_arr) {
        week_arr.forEach(weekday => {
          switch(weekday) {
            case 0: 
              this.modal.sun = true;
              break;
            case 1:
              this.modal.mon = true;
              break;
            case 2:
              this.modal.tue = true;
              break;
            case 3:
              this.modal.wed = true;
              break;
            case 4:
              this.modal.thu = true;
              break;
            case 5:
              this.modal.fri = true;
              break;
            case 6:
              this.modal.sat = true;
              break;
          }
        })
      },

      async editRule(filename, campaign_id, rule_id) {
        const token = await this.$auth.getTokenSilently();
        axios.get("/config-1.0/campaigns/" + campaign_id + "/rules/" + rule_id, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.modal.edit = true;
          this.modal.filename = filename;
          this.modal.short_desc = response.data.short_description;
          this.modal.notes = response.data.notes;
          this.modal.uuid = rule_id;
          
          response.data.predicates.forEach(predicate => {
            Object.keys(predicate).forEach(rule => {
              let pos = this.draggable_rules.findIndex(i => i.what == rule);
              let tmp_rule = this.draggable_rules.splice(pos, 1)[0];

              this.modal_rules.push(tmp_rule);

              switch(rule) {
                case 'DateRange':
                  this.modal.dateStart = predicate[rule]['start_date'];
                  this.modal.dateEnd = predicate[rule]['end_date'];
                  break;
                case 'TimeRange':
                  this.modal.timeStart = predicate[rule]['start_time'].slice(0, -3);
                  this.modal.timeEnd = predicate[rule]['end_time'].slice(0, -3);
                  break;
                case 'DaysOfWeek':
                  this.convertDaysOfWeek(predicate[rule]['days']);
                  break;
                case 'OnMinutes':
                  this.modal.onMinutes = predicate[rule]['minutes'].join(',');
                  break;
                case 'OnHours':
                  this.modal.onHours = predicate[rule]['hours'].join(',');
                  break;
                case 'ExactTime':
                  this.modal.exactTime = predicate[rule]['fire_time'].slice(0, -3);
                  break;
                case 'EveryNMinutes':
                  this.modal.everyNMinutes = predicate[rule]['n'];
                  break;
                case 'EveryNHours':
                  this.modal.everyNHours = predicate[rule]['n'];
                  break;
                case 'Probability':
                  this.modal.probability = predicate[rule]['pct_probability'];
                  break;
                case 'RepetitionsPerHour':
                  this.modal.repetitionsPerHour = predicate[rule]['repetitions'];
                  break;
                case 'RepetitionsPerTimeRange':
                  this.modal.repsTimeStart = predicate[rule]['start_time'].slice(0, -3);
                  this.modal.repsTimeEnd = predicate[rule]['end_time'].slice(0, -3);
                  this.modal.reps = predicate[rule]['n'];
                  break;
              }
            });
          });
        
          this.openModal({original_filename: filename, sha256sum: response.data.target_asset});
        });
      }
    }
  }
</script>

<style scoped>
</style>