<template>
  <div class="d-flex justify-content-left mt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Telemetry</li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-center">
    <h1>Telemetry</h1>
  </div>
  
  <div>
    <div class="row mb-3">
      <div class="col text-end">
        <a href="#" class="btn btn-primary" @click="reloadTelemetry(selected_machine)"><fa :icon="['fas', 'sync-alt']" /> Reload</a>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-7">
      </div>
      <div class="col text-end">
        Machine ID: 
        <Multiselect
          v-model="selected_machine"
          mode="single"
          :searchable="true"
          :options="machines" 
          @change="onChange($event)" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <div class="card" v-if="loaded">
          <h5 class="card-header">Machine States</h5>
          <div class="card-body">
            <h5 class="card-title"></h5>
            <p class="card-text">
              Git Sha: {{ machine.git_sha }}
              <br /><br />
              Cargo Version: {{ machine.cargo_version }}
              <br /><br />
              Build Time: {{ machine.build_time }}
              <br /><br />
              {{ machine.player_service.description }} at {{ formatTime(machine.player_service.transition_time) }}
              <br /><br />
              {{ machine.media_manager.description }} at {{ formatTime(machine.media_manager.transition_time) }}
              <br /><br />
              {{ machine.playlist_manager.description }} at {{ formatTime(machine.playlist_manager.transition_time) }}
              <br /><br />
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" v-if="loaded2">
          <h5 class="card-header">Play History</h5>
          <div class="card-body">
            <h5 class="card-title"></h5>
            <div class="card-text text-end">
              Start <input v-model="search_start" type="text" placeholder="mm/dd/yyyy hh:mm" />
              to
              End <input v-model="search_stop" type="text" placeholder="mm/dd/yyyy hh:mm" />
            </div><br />
            <p class="card-text" v-if="! isNaN(search_start) && ! isNaN(search_stop)">
              <strong>Plays in the Last Hour.</strong>
            </p>
            <p class="card-text" v-for="(media, i) in machine.plays" :key="i">
              Media: {{ media.media_name }}<br />
              Start Time: {{ formatTime(media.play_start_time) }}<br />
              End Time: {{ formatTime(media.play_end_time) }}<br /><br />
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { ref, reactive, onMounted, inject } from 'vue';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';

export default {
  components: {
      Multiselect,
    },

  props: {
    machine_id: {
      type: String,
      required: false,
      default: '',
    }
  },
    
  setup(props) {
    const selected_machine = ref('');
    const machines = ref([]);
    const machine = reactive({
      player_service: {},
      media_manager: {},
      playlist_manager: {},
      plays: [],
      git_sha: '',
      cargo_version: '',
      build_time: '',
    });

    const loaded = ref(false);
    const loaded2 = ref(false);

    const search_start = ref('');
    const search_stop = ref('');

    onMounted(async() => {
      const token = await inject('authToken');

      axios.get("/config-1.0/config/profiles?type=physical", {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          let ret_array = [];
          response.data.forEach((machine) => {
            ret_array.push({"value": machine.machine_id, "label": `${machine.machine_id}: (${machine.logical_id})`});
          });

          machines.value = ret_array;

          if (props.machine_id !== '') {
            selected_machine.value = props.machine_id;
            const now = new Date();

            axios.get("/telemetry-1.0/monitoring/appliance/state/" + props.machine_id, {
              'headers': {
                'Authorization': `Bearer ${token}`
              }
            }).then(response => {
              machine.player_service = response.data.service_states.player_service || new Object({ description: "Player Service not reporting", transition_time: now.getTime()/1000 });
              machine.media_manager = response.data.service_states.media_manager || new Object({ description: "Media Manager not reporting", transition_time: now.getTime()/1000 });
              machine.playlist_manager = response.data.service_states.playlist_manager || new Object({ description: "Playlist Manager not reporting", transition_time: now.getTime()/1000 });
              machine.git_sha = response.data.git_sha;
              machine.cargo_version = response.data.cargo_version;
              machine.build_time = response.data.build_time;
              loaded.value = true;
            });

            axios.get("/telemetry-1.0/monitoring/appliance/plays/" + props.machine_id, {
              'headers': {
                'Authorization': `Bearer ${token}`
              }
            }).then(response => {
              machine.plays = response.data;
              machine.plays.reverse();

              loaded2.value = true;
            });
          }
        });       
    });

    return {
      selected_machine,
      machines,
      machine,
      loaded,
      loaded2,
      search_start,
      search_stop,
    }
  },

  computed: {
  },

  methods: {
    async onChange(e) {
      const token = await this.$auth.getTokenSilently();
      
      axios.get("/telemetry-1.0/monitoring/appliance/state/" + e, {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        const now = new Date();

        this.machine.player_service = response.data.service_states.player_service || new Object({ description: "Player Service not reporting", transition_time: now.getTime()/1000 });
        this.machine.media_manager = response.data.service_states.media_manager || new Object({ description: "Media Manager not reporting", transition_time: now.getTime()/1000 });
        this.machine.playlist_manager = response.data.service_states.playlist_manager || new Object({ description: "Playlist Manager not reporting", transition_time: now.getTime()/1000 });
        this.machine.git_sha = response.data.git_sha;
        this.machine.cargo_version = response.data.cargo_version;
        this.machine.build_time = response.data.build_time;

        this.loaded = true;
      });

      axios.get("/telemetry-1.0/monitoring/appliance/plays/" + e, {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        this.machine.plays = response.data;
        this.machine.plays.reverse();

        this.loaded2 = true;
      });
    },

    formatTime(time) {
      return new Date(time*1e3).toString();
    },

    async reloadTelemetry(e) {
      let dt_start = new Date(this.search_start).getTime() /1000;
      let dt_end = new Date(this.search_stop).getTime() /1000;

      const token = await this.$auth.getTokenSilently();

      axios.get("/telemetry-1.0/monitoring/appliance/state/" + e, {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        const now = new Date();

        this.machine.player_service = response.data.service_states.player_service || new Object({ description: "Player Service not reporting", transition_time: now.getTime()/1000 });
        this.machine.media_manager = response.data.service_states.media_manager || new Object({ description: "Media Manager not reporting", transition_time: now.getTime()/1000 });
        this.machine.playlist_manager = response.data.service_states.playlist_manager || new Object({ description: "Playlist Manager not reporting", transition_time: now.getTime()/1000 });
        this.machine.git_sha = response.data.git_sha;
        this.machine.cargo_version = response.data.cargo_version;
        this.machine.build_time = response.data.build_time;
        this.loaded = true;
      });

      let url = "/telemetry-1.0/monitoring/appliance/plays/" + e;

      if (! isNaN(dt_start) && ! isNaN(dt_end)) {
        url += `?start_time=${dt_start}&end_time=${dt_end}`;
      }

      axios.get(url, {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        this.machine.plays = response.data;
        this.machine.plays.reverse();

        this.loaded2 = true;
      });
    }
  }
}
</script>

<style scoped>

</style>