import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import ConfigMgmt from "@/views/ConfigMgmt.vue";
import Config from "@/views/Config.vue";
import Configs from "@/views/Configs.vue";
import MediaLibrary from "@/views/MediaLibrary.vue";
import IndexMgmt from "@/views/IndexMgmt.vue";
import Index from "@/views/Index.vue";
import MediaByIndex from "@/views/MediaByIndex.vue";
import MediaUpload from "@/views/MediaUpload.vue";
import Telemetry from "@/views/Telemetry.vue";
import CampaignMgmt from "@/views/CampaignMgmt.vue";
import Campaign from "@/views/Campaign.vue";
import ModifyIndex from "@/views/ModifyIndex.vue";
import { routeGuard } from "@/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/config-future",
    name: "Create Config",
    component: ConfigMgmt,
    beforeEnter: routeGuard
  },
  {
    path: "/config",
    name: "Create Configuration",
    component: Config,
    beforeEnter: routeGuard
  },
  {
    path: "/config/:machine_id",
    name: "Edit Configuration",
    component: Config,
    beforeEnter: routeGuard,
    props: true
  },
  {
    path:"/configs",
    name: "Config Overview",
    component: Configs,
    beforeEnter: routeGuard
  },
  {
    path: "/media-library",
    name: "Media Library",
    component: MediaLibrary,
    beforeEnter: routeGuard
  },
  {
    path: "/index-mgmt",
    name: "Index Management",
    component: IndexMgmt,
    beforeEnter: routeGuard
  },
  {
    path: "/index",
    name: "Create Index",
    component: Index,
    beforeEnter: routeGuard
  },
  {
    path: "/index/:index_name",
    name: "Edit Index",
    component: Index,
    beforeEnter: routeGuard,
    props: true
  },
  {
    path: "/media-by-index/:index_name?",
    name: "Media By Index",
    component: MediaByIndex,
    beforeEnter: routeGuard,
    props: true
  },
  {
    path: "/media-upload",
    name: "Media Upload",
    component: MediaUpload,
    beforeEnter: routeGuard
  },
  {
    path: "/telemetry/:machine_id?",
    name: "Telemetry",
    component: Telemetry,
    beforeEnter: routeGuard,
    props: true
  },
  {
    path: "/campaigns",
    name: "Campaign Management",
    component: CampaignMgmt,
    beforeEnter: routeGuard,
  },
  {
    path: "/campaign/:campaign_id?",
    name: "Campaign",
    component: Campaign,
    beforeEnter: routeGuard,
    props: true
  },
  {
    path: "/remove-from-index",
    name: "Remove From Index",
    component: ModifyIndex,
    beforeEnter: routeGuard,
    props: true
  },
  {
    path: "/add-to-index",
    name: "Add To Index",
    component: ModifyIndex,
    beforeEnter: routeGuard,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;