<template>
  <div class='d-flex justify-content-left mt-3'>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active"><a href="/media-library">Media Library</a></li>
      </ol>
    </nav>
  </div>

  <div class="d-flex justify-content-center">
    <h1>Media Library</h1>
  </div>

  <div class="row mb-3">
    <div class="col-10">
    </div>
    <div class="col-2 text-end">
      <label for="" class="form_label">Media Type: </label>
      &nbsp;
      <select class="form-select" v-model="chosen_media">
        <option v-for="(medium, i) in media_type" :key="i" :value="medium">
          {{ medium }}
        </option>
      </select>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-10">
    </div>
    <div class="col-2 text-end">
      <select class="form-select" v-model="search_for_limit">
        <option v-for="(type, i) in search_for_types" :key="i" :value="type">
          {{ type }}
        </option>
      </select>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-10">
    </div>
    <div class="col-2 text-end">
      <input  v-model="search_for" type="text" id="filename" class="form-control" />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col text-end">
      <span v-if="selected_shas.length">
        <button type="button" class="btn btn-primary" @click="openToIndex('add')">
          Add to Index
        </button>
        &nbsp;
        <button type="button" class="btn btn-danger" @click="openToIndex('delete')">
          Remove from Index
        </button>
        &nbsp;
      </span>
      <button type="button" class="btn btn-primary" @click="searchLibrary">
        <fa :icon="['fas', 'sync-alt']" /> Reload
      </button>  
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              Media File
              <a href="#" @click.prevent="toggleSort('filename')">
                <fa v-if="sort_filename == -1" :icon="['fas', 'sort']" />
                <fa v-if="sort_filename == 0" :icon="['fas', 'sort-up']" />
                <fa v-if="sort_filename == 1" :icon="['fas', 'sort-down']" />
              </a> 
            </th>
            <th>
              Media Type
            </th>
            <th>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(index, i) in media_list" :key="i">
            <td>
              <input v-model="selected_shas" type="checkbox" class="form-checkbox" :name="index.sha256sum" :value="index.sha256sum + ':' + index.original_filename" />
              {{ index.original_filename }}
            </td>
            <td>{{ index.asset_type }}</td>
            <td>
              <a href="#" class="btn btn-primary" data-bs-toggle="tooltip"
                data-bs-placement="bottom" title="View Media Statistics"
                @click.prevent="openModal('dataModel', index.sha256sum)">
                <fa :icon="['fas', 'external-link-alt']" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal fade" id="dataModel" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ media_data.filename }} ({{ media_data.sha256sum }})</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <media-stats :media-data="media_data" what="Add to" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { ref, reactive, onMounted, toRaw } from 'vue';
  import axios from 'axios';
  import { Modal } from 'bootstrap';
  import MediaStats from "@/components/MediaStats.vue";
   
  export default {
    components: {
      MediaStats,
    },
    
    setup() {
      const chosen_media = ref('SPOT');
      const media_type = [
        "MUSIC",
        "SPOT"
      ];
      const search_for_types = [
        "BEGINS_WITH",
        "FULL"
      ];
      const search_for = ref('');
      const search_for_limit = ref('BEGINS_WITH');
      const media_list = ref([]);
      const sort_filename = ref(-1);
      const selected_shas = ref([]);

      const media_data = reactive({
        asset_type: '',
        duration: '',
        encoding: '',
        channels: '',
        sample_rate: '',
        sample_precision: '',
        file_type: '',
        avg_bitrate: '',
        file_size: '',
        comments: ''
      });

      onMounted(async() => {       
      });

      return {
        chosen_media,
        media_type,
        search_for_types,
        search_for,
        search_for_limit,
        media_list,
        sort_filename,
        selected_shas,
        media_data,
      }
  },
    methods: {
      async searchLibrary() {
        const token = await this.$auth.getTokenSilently();
        let base_uri = "/media-1.0/assets?assetType=" + this.chosen_media;

        if (this.search_for !== '') {
          base_uri += "&filenameMatchType=" + this.search_for_limit + "&filename=" + this.search_for;
        }

        axios.get(base_uri, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.media_list = response.data;
        });
      },

      toggleSort(what) {
          if (what == "filename") {
            this.sort_filename = ! this.sort_filename;
          }

          this.sortArray(what);
        },

      sortArray(what) {
        let sort_by;

        if (what == 'filename') {
          sort_by = 'original_filename';
        }

        if (this.sort_filename == 0) {
          toRaw(this.media_list).sort(this.compareValues(sort_by));
        }
        else if (this.sort_filename == 1) {
          toRaw(this.media_list).sort(this.compareValues(sort_by, 'desc'));
        }
      },

      compareValues(key, order='asc') {
        return function(a, b) {
          if (! Object.prototype.hasOwnProperty.call(a, key) 
            || ! Object.prototype.hasOwnProperty.call(b, key)) {
            // property doesn't exist on either object
            return 0;
          }

          const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
          const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

          let comparison = 0;
        
          if (varA > varB) {
            comparison = 1;
          }
          else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order == 'desc') ? (comparison * -1) : comparison
          );
        };
      },

      async openModal(modal, sha) {
        const token = await this.$auth.getTokenSilently();

        axios.get("/media-1.0/assets/" + sha, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.media_data.filename = response.data.original_filename;
          this.media_data.sha256sum = response.data.sha256sum;
          this.media_data.asset_type = response.data.asset_type;
        });

        axios.get("/media-1.0/assets/stats/" + sha, {
          'headers': {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.media_data.duration = response.data.duration;
          this.media_data.encoding = response.data.encoding;
          this.media_data.channels = response.data.channels;
          this.media_data.sample_rate = response.data.sample_rate;
          this.media_data.sample_precision = response.data.sample_precision;
          this.media_data.file_type = response.data.file_type;
          this.media_data.avg_bitrate = response.data.avg_bitrate;
          this.media_data.file_size = response.data.file_size;
          this.media_data.comments = response.data.comments;
        });

        const myModal = new Modal(document.getElementById(modal), {
          keyboard: false
        });

        myModal.toggle();
      },

      openToIndex(what) {
        let go_where = (what == 'add') ? "Add To Index": "Remove From Index";

        this.$router.push({
          name: go_where,
          query: {
            'shas': this.selected_shas,
            'what': what,
            'chosen_media': this.chosen_media,
          }
        })
      },
    },
  }
</script>

<style scoped>

</style>